<template>
  <v-app id="app">
 
      <router-view/>

  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>

<style>
html, body, #app, #main {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;

}
</style>
