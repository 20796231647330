import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import vuetify from './plugins/vuetify'
import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'
import Vconsole from 'vconsole'

// 测试和开发打开，生产不能打开
if (process.env.NODE_ENV !== 'production') { 
  // let vConsole = new Vconsole()
  // Vue.use(vConsole)
}
Vue.use(preview)
Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
